<template>
  <div>
    <Navbar :page="'Legítimo interesse'" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

      <div class="grid grid-cols-12 gap-4 mb-3">
        <div class="col-span-12 md:col-span-2 mb-1 mt-1">
            <input v-model="busca" type="text" name="busca" id="busca" placeholder="Buscar atividade" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <div v-if="tipo_responsavelQMA === 'pessoa'" class="col-span-12 md:col-span-3 mb-1 mt-1">
            <select v-model="pessoa_responsavelQMA" name="pessoa_responsavelQMA" id="pessoa_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option default :value="null">Todos os responsáveis pelo preenchimento</option>
                <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
            </select>
        </div>
        <div v-if="tipo_responsavelQMA === 'profissional'" class="col-span-12 md:col-span-3 mb-1 mt-1">
            <select v-model="profissional_responsavelQMA" name="profissional_responsavelQMA" id="profissional_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option default :value="null">Todos os responsáveis pelo preenchimento</option>
                <option v-for="profissional in profissionais" :key="profissional._id" :value="profissional._id">{{ profissional.nome }}</option>
            </select>
        </div>
        <div class="col-span-12 md:col-span-3 mb-1 mt-1">
          <select v-model="responsavel" name="responsavel" id="responsavel" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Por resp. pela atividade mapeada</option>
              <option v-for="responsavel in pessoas" :key="responsavel._id" :value="responsavel._id">{{ responsavel.nome }}</option>
          </select>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-1">
          <select v-model="setor" name="setor" id="setor" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Todos os setores</option>
              <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
          </select>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button @click="updateFiltro" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2" v-if="!$store.state.user.cliente">
          <label for="naoContemAvaliacao" class="block text-sm font-medium mt-2">
            <input type="checkbox" v-model="desativadas" class="rounded-sm" id="naoContemAvaliacao">
            <span class="ml-2">
                Visualizar desativadas
            </span>
          </label>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th v-if="!$store.state.user.cliente" scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider cursor-pointer">
                      <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#000000" viewBox="0 0 256 256"><path d="M220,48V208a12,12,0,0,1-12,12H136a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM117.17,157.17,64,210.34,42.83,189.17a4,4,0,0,0-5.66,5.66l24,24a4,4,0,0,0,5.66,0l56-56a4,4,0,0,0-5.66-5.66Z"></path></svg>
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      REF
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Atividade / Processo
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Atualização
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Motivo para TLI / LIA
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id">
                   
                    <td v-if="!$store.state.user.cliente" class="px-2 py-3 whitespace-nowrap">
                      <div class="flex items-center">
                        <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                      </div>
                    </td>

                    <td class="px-2 py-3 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.ref ? item.ref : ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.nome ? item.nome : ''}}
                        </div>
                      </div>
                    </td>           
                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div v-if="item.dataAtualizacao" class="text-sm_2 text-gray-500">
                          {{ item.dataAtualizacao  | moment("DD/MM/YYYY HH:mm")}}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-3 text-center">
                      <div class="text-sm_2 flex-col text-gray-500">
                        {{ (item.baselegal.findIndex((i) => i.artigo === artigoLegitimoInteresse) !==-1) ? 'Legítimo interesse':'' }}
                      </div>
                      <div class="text-sm_2 flex-col text-gray-500">
                        {{ item.titularmenor ? 'Criança e adolescente': '' }}
                      </div>
                    </td>
                  
                    <td v-if="!desativadas" class="flex flex-wrap px-2 py-3 text-sm font-medium">
                   
                      <router-link 
                        :to="`/${route_navigation}/form/${item._id}`"
                        :class="`${item.legitimoInteresse ? 'hover:bg-yellow-400 bg-yellow-500':'hover:bg-green-400 bg-green-500'} text-white py-1 px-2 mx-1 my-1`">
                        {{ item.legitimoInteresse ? 'Atualizar teste': 'Iniciar teste' }} 
                      </router-link>

                      <button
                        v-if="item.legitimoInteresse "
                        class="inline-block text-white hover:bg-blue-500 bg-blue-700 py-1 px-2 mx-1 my-1">
                        <a rel="noreferrer" :href="`${url_api}/v1/legitimoInteresse/download/${item.legitimoInteresse}?token=${$store.state.token}`" >
                            Relatório
                        </a>
                      </button>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <label for="perPage">Itens por página

              <select class="w-20 mt-2 mr-1 py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" @change="start" v-model="perPage">
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>

            </label>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      artigoLegitimoInteresse: 'art. 7, IX, LGPD',
      route_navigation: 'entregaveis/legitimoInteresse',
      route_backend: 'atividades',
      responsavel: null,
      tipo_responsavelQMA: null,
      pessoa_responsavelQMA:  null,
      profissional_responsavelQMA: null,
      resumo: null,
      selecionados: [],
      optionsGeral: {
        chart: {
          id: 'chartGeral',
          type: 'donut',
        },
        labels: ["Gerados", "Pendentes", "Respondidos", "Revisados", "Finalizados"],
        colors: ['#FF4560', '#F3B01B', '#008FFB', '#775DD0', '#00E396']
      },
      seriesGeral: [0, 0, 0, 0, 0],
      qtdDados: 0,
      qtdDadosSensiveis: 0,
      qtdDadosCriticos: 0,
      list: [],
      page: 1,
      perPage: 20,
      busca: '',
      desativadas: false,
      setores: [],
      profissionais: [],
      pessoas: [],
      setor: null,
      total: 0,
      query: { page: 1, limit: 10, skip: 0, desativadas: false },
    }
  },
  methods: {
    async start() {
      this.selecionados = [];

      this.query.limit = this.perPage;
      const listReq = await this.$http.post(`/v1/${this.route_backend}/list`, 
      { 
        busca: this.busca, 
        setor: this.setor, 
        desativadas: this.desativadas, 
        responsavel: this.responsavel, 
        profissional_responsavelQMA: this.profissional_responsavelQMA, 
        pessoa_responsavelQMA: this.pessoa_responsavelQMA, 
        tipo_responsavelQMA: this.tipo_responsavelQMA, 
        legitimoInteresse: true,
        titularmenor: true,
        baselegal: '63b8691056a50550e8e00aee',
        baseLegalLegitmo: true,
        skip: this.query.skip, 
        limit: this.query.limit
      });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },
    updateFiltro(){
      console.log("update filtro");
      this.query.page = 1;
      this.query.limit = this.perPage;
      this.query.skip = 0;

      this.query = { 
        page: this.query.page, 
        limit: this.query.limit, 
        skip: this.query.skip, 
        busca: this.busca, 
        desativadas: this.desativadas, 
        setor: this.setor, 
        responsavel: this.responsavel, 
        profissional_responsavelQMA: this.profissional_responsavelQMA, 
        pessoa_responsavelQMA: this.pessoa_responsavelQMA, 
        tipo_responsavelQMA: this.tipo_responsavelQMA 
      };
      this.$router.push({ path: `/entregaveis/legitimoInteresse`, query: this.query });
    },

    async setPage(page) {
      console.log("SET PAGE");
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.busca = this.busca;
      this.query.setor = this.setor;
      this.query.pessoa_responsavelQMA = this.pessoa_responsavelQMA;
      this.query.profissional_responsavelQMA = this.profissional_responsavelQMA;
      this.query.responsavel = this.responsavel;
      this.query.tipo_responsavelQMA = this.tipo_responsavelQMA
      this.query.desativadas = this.desativadas;

      this.$router.push({ path: `/entregaveis/legitimoInteresse`, query: this.query });
    },

    async selecionarTodos(){
      if (!this.selecionados.length) {
        const listReq = await this.$http.post(`/v1/${this.route_backend}/list`, { 
          all: true, 
          busca: this.busca, 
          setor: this.setor, 
          desativadas: this.desativadas, 
          responsavel: this.responsavel, 
          profissional_responsavelQMA: this.profissional_responsavelQMA, 
          pessoa_responsavelQMA: this.pessoa_responsavelQMA, 
          tipo_responsavelQMA: this.tipo_responsavelQMA,
          legitimoInteresse: true,
          titularmenor: true,
          baselegal: '63b8691056a50550e8e00aee',
        });

        this.selecionados = listReq.data.data
    

      } else {
        this.selecionados = [];
      }
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    getNameFuncao(item){
      if(item.tipo_responsavelQMA === 'consultor' && !item.revisado && item.status === 'Gerada') return 'Iniciar';
      if(this.$store.state.user.cliente) return 'Responder'
      if(this.$store.state.user.profissional && item.profissional_responsavelQMA && item.profissional_responsavelQMA._id.toString() === this.$store.state.user._id.toString()) return 'Responder';
      return item.revisado ? 'Atualizar' : item.gerada && item.revisado ? 'Revisar' : 'Atualizar';
    },

    verifOpacity(item){
      if(item.tipo_responsavelQMA === 'consultor') return true;
      if(!item.revisado) return true;
      if(!item.gerada) return true;
      if(item.profissional_responsavelQMA && item.profissional_responsavelQMA._id && item.profissional_responsavelQMA._id.toString() === this.$store.state.user._id.toString()) return true;
      return false;
    }

  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.setor) this.setor = this.$route.query.setor;
    if(this.$route.query.responsavel) this.responsavel = this.$route.query.responsavel;
    if(this.$route.query.profissional_responsavelQMA) this.profissional_responsavelQMA = this.$route.query.profissional_responsavelQMA;
    if(this.$route.query.pessoa_responsavelQMA) this.pessoa_responsavelQMA = this.$route.query.pessoa_responsavelQMA;
    if(this.$route.query.tipo_responsavelQMA) this.tipo_responsavelQMA = this.$route.query.tipo_responsavelQMA;
    if(this.$route.query.desativadas) this.desativadas = this.$route.query.desativadas === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.setor) this.setor = this.$route.query.setor;
    if(this.$route.query.status) this.status = this.$route.query.status;
    if(this.$route.query.responsavel) this.responsavel = this.$route.query.responsavel;
    if(this.$route.query.profissional_responsavelQMA) this.profissional_responsavelQMA = this.$route.query.profissional_responsavelQMA;
    if(this.$route.query.pessoa_responsavelQMA) this.pessoa_responsavelQMA = this.$route.query.pessoa_responsavelQMA;
    if(this.$route.query.tipo_responsavelQMA) this.tipo_responsavelQMA = this.$route.query.tipo_responsavelQMA;
    if(this.$route.query.desativadas) this.desativadas = this.$route.query.desativadas === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();

    const listSetores = await this.$http.post(`/v1/setores/list`, { all: true });
    this.setores = listSetores.data.data;

    const pessoasReq = await this.$http.post(`/v1/pessoas/list`, { all: true });
    this.pessoas = pessoasReq.data.data;

    const profissionaisRef = await this.$http.post(`/v1/profissionais/listProfissionaisEmpresa`);  
    this.profissionais = profissionaisRef.data.data;

    this.$watch('desativadas', async (desativada) => {
      this.selecionados = [];
      const listReq = await this.$http.post(`/v1/${this.route_backend}/list`, 
      { 
        busca: this.busca, 
        setor: this.setor, 
        desativadas: desativada, 
        responsavel: this.responsavel, 
        profissional_responsavelQMA: this.profissional_responsavelQMA, 
        pessoa_responsavelQMA: this.pessoa_responsavelQMA, 
        tipo_responsavelQMA: this.tipo_responsavelQMA, 
        legitimoInteresse: true,
        titularmenor: true,
        baselegal: '63b8691056a50550e8e00aee',
        skip: this.query.skip, 
        limit: this.query.limit 
      });
      this.list = listReq.data.data;

      this.total = this.data.length;
    });

    this.$watch('tipo_responsavelQMA', async () => {
      this.pessoa_responsavelQMA = null;
      this.profissional_responsavelQMA = null;
    });
  },
}
</script>