<template>
  <div>
    <Navbar page="Diagnósticos ativos" video="https://www.youtube.com/watch?v=V9KF8NtifIU" />
    <div class="mx-4 my-4 md:mx-8 md:my-8 ">
      <div v-if="!$store.state.user.cliente" class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 md:col-span-5 rounded-lg px-1 py-2">
          <h2 class=" mb-3 font-semibold">Nível de maturidade geral (Dignósticos ativos)</h2>
            <apexchart height="240" type="donut" :options="optionsGeral" :series="seriesGeral"></apexchart>
        </div>
        
        <div class="col-span-12 md:col-span-7 flex flex-col gap-2 justify-around">
          <ul class="py-8 px-8 bg-gray-100">
            <li>
              Total de diagnósticos ativos: {{resumo ? formatIntNumber(resumo.totalAtivos || 0) : 0}}
            </li> 
            <li>
              Total de diagnósticos arquivados: {{resumo ? formatIntNumber(resumo.totalArquivados || 0): 0}}
            </li>
          </ul>

          <div class="flex gap-1">
          <div>
            <svg 
              class="h-10 w-10 fill-gray-500"
              version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               stroke="none">
              <path d="M2504 5019 c-17 -19 -19 -41 -22 -183 -4 -184 6 -230 52 -242 45 -11
              76 4 92 43 12 29 15 70 12 199 -3 186 -10 204 -78 204 -25 0 -43 -7 -56 -21z"/>
              <path d="M1480 4758 c-28 -31 -25 -61 16 -142 77 -150 145 -257 172 -267 60
              -23 115 38 93 103 -16 44 -148 277 -174 306 -27 29 -79 30 -107 0z"/>
              <path d="M3533 4758 c-27 -29 -160 -263 -174 -306 -22 -66 33 -126 93 -103 39
              15 208 309 208 363 0 61 -85 91 -127 46z"/>
              <path d="M2388 4359 c-93 -13 -120 -23 -142 -52 -24 -33 -15 -70 24 -98 24
              -17 30 -17 125 -3 122 19 332 14 450 -11 257 -54 484 -177 669 -361 175 -175
              285 -368 354 -624 24 -88 26 -111 26 -305 0 -204 -1 -214 -32 -328 -36 -137
              -115 -309 -194 -424 -29 -43 -111 -136 -181 -208 -177 -179 -251 -305 -275
              -466 l-7 -54 -183 -3 -182 -2 0 345 c0 332 -1 346 -20 365 -30 30 -83 27 -109
              -6 -20 -26 -21 -38 -21 -365 l0 -339 -105 0 -105 0 0 585 0 585 104 0 105 0 3
              -146 c3 -139 4 -147 27 -165 30 -25 62 -24 95 2 26 20 26 21 26 164 l0 143 99
              4 c88 3 104 7 147 34 134 82 172 251 84 373 -124 171 -390 131 -458 -68 -7
              -19 -12 -69 -12 -112 l0 -79 -109 0 -108 0 -6 89 c-11 179 -102 274 -262 275
              -151 1 -255 -104 -255 -257 0 -112 66 -205 170 -242 24 -8 77 -15 121 -15 l79
              0 0 -585 0 -585 -178 0 -179 0 -17 83 c-33 153 -119 302 -243 417 -84 79 -168
              175 -219 251 -355 534 -279 1235 182 1680 106 102 188 161 309 223 50 26 96
              54 103 63 32 44 -3 113 -59 113 -34 0 -142 -52 -244 -118 -328 -211 -563 -561
              -642 -959 -22 -111 -24 -402 -4 -513 60 -328 212 -604 474 -856 133 -129 193
              -248 206 -411 8 -95 25 -123 78 -123 l33 0 0 -90 0 -90 -59 0 c-52 0 -62 -3
              -80 -26 -28 -35 -26 -69 4 -99 20 -21 34 -25 80 -25 l55 0 0 -80 0 -80 -59 0
              c-52 0 -62 -3 -80 -26 -28 -35 -26 -69 4 -99 20 -21 34 -25 80 -25 l55 0 0
              -32 c0 -45 40 -126 81 -167 39 -38 102 -70 139 -71 34 0 41 -7 69 -62 34 -67
              113 -143 190 -181 62 -30 72 -32 176 -32 103 0 114 2 177 32 80 38 152 106
              194 183 27 50 33 55 73 62 112 19 211 128 211 233 l0 35 54 0 c68 0 106 26
              106 74 0 54 -20 70 -92 76 l-63 5 -3 78 -3 77 54 0 c69 0 107 26 107 74 0 54
              -20 70 -92 76 l-63 5 0 85 0 85 38 6 c48 8 66 32 67 89 1 75 28 181 66 259 30
              61 62 101 178 221 241 248 362 463 428 755 25 115 36 388 19 507 -67 468 -355
              878 -772 1098 -161 84 -321 136 -492 159 -90 13 -305 13 -389 0z m-124 -1411
              c41 -18 59 -56 64 -135 l5 -73 -78 0 c-74 0 -78 1 -111 34 -27 27 -34 42 -34
              74 0 82 80 134 154 100z m752 -7 c42 -26 60 -78 44 -127 -18 -52 -59 -74 -142
              -74 l-68 0 0 74 c0 60 4 78 21 100 38 49 93 58 145 27z m74 -1761 l0 -90 -505
              0 -505 0 0 90 0 90 505 0 505 0 0 -90z m0 -320 l0 -80 -505 0 -505 0 0 80 0
              80 505 0 505 0 0 -80z m0 -251 c0 -27 -27 -72 -54 -90 -17 -11 -95 -15 -411
              -17 -214 -2 -409 0 -433 3 -53 8 -100 48 -108 93 l-6 32 506 0 506 0 0 -21z
              m-295 -283 c-18 -27 -77 -70 -118 -85 -87 -33 -218 0 -286 73 l-35 36 227 0
              227 0 -15 -24z"/>
              <path d="M755 3975 c-32 -31 -33 -75 -2 -102 45 -41 288 -173 318 -173 59 0
              91 73 52 118 -26 29 -295 182 -322 182 -12 0 -33 -11 -46 -25z"/>
              <path d="M4150 3915 c-79 -46 -150 -92 -157 -101 -19 -26 -16 -71 7 -94 35
              -35 71 -25 212 56 157 90 178 107 178 146 0 35 -40 78 -72 78 -13 0 -88 -38
              -168 -85z"/>
              <path d="M490 2950 c-25 -25 -26 -71 -1 -101 19 -24 22 -24 206 -24 184 0 187
              0 206 24 25 30 24 76 -1 101 -19 19 -33 20 -205 20 -172 0 -186 -1 -205 -20z"/>
              <path d="M4220 2950 c-25 -25 -26 -71 -1 -101 19 -24 22 -24 206 -24 184 0
              187 0 206 24 25 30 24 76 -1 101 -19 19 -33 20 -205 20 -172 0 -186 -1 -205
              -20z"/>
              <path d="M934 2028 c-78 -46 -149 -89 -158 -97 -22 -19 -21 -83 2 -104 35 -32
              75 -20 214 59 142 82 178 111 178 148 0 33 -29 65 -65 71 -25 5 -55 -9 -171
              -77z"/>
              <path d="M3990 2097 c-13 -7 -29 -25 -35 -40 -17 -49 9 -75 160 -164 150 -87
              196 -100 228 -65 22 25 22 85 0 104 -29 25 -298 178 -314 178 -8 0 -25 -6 -39
              -13z"/>
              </g>
              </svg>
          </div>
          <div class="text-gray-500 flex flex-col text-sm text-justify">
            <span class="font-bold">
              Orientação rápida
            </span>
            <span>
              Ao clicar em "enviar", você poderá enviar um questionário de diagnóstico para um ou mais destinatários. Se
              optar pela função "editar", terá a oportunidade de criar diagnósticos personalizados, modificar ou adquirir
              questionários já disponíveis. Para mais detalhes, assista ao nosso tutorial clicando no ícone no canto superior
              direito do módulo
            </span>
          </div>
        </div>
        </div>
      </div>
      <div class="flex gap-2">
        <router-link :to="`${route}/form`" type="button" 
        :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
        class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 
        focus:bg-yellow-700 focus:shadow-sm focus:ring-4 
        focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 
        rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center
        mb-4"
        >
            <span class="inline-block mr-2">Iniciar envios</span>
        </router-link>

        <router-link :to="`${route}/gerenciar`" type="button" 
          :style="{ 'background-color': $store.state.colorSecundaria, 'color': invertColor($store.state.colorPrincipal, true) }"
          class="inline-block transition duration-200 bg-red-600 hover:bg-red-700 text-white py-2 
          rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center
          mb-4
          "
        >
            <span class="inline-block mr-2">Editar/criar modelos</span>
        </router-link>

        <div class="col-span-12 2xl:col-span-4 flex py-2">
            <input 
              type="checkbox" 
              v-model="arquivados" 
              @change="updateFiltro" 
              class="rounded-sm" 
              id="visualizarArquivados"
            />
            <label for="visualizarArquivados" class="block text-sm font-medium ml-2">
                Visualizar arquivados
            </label>
        </div>
        
      </div>

      <button 
        @click="excluirSelecionadas" 
        v-if="!$store.state.user.cliente && selecionados.length && !arquivados" 
        type="button" 
        class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center ">
        <span class="inline-block">Arquivar selecionadas</span>
      </button>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50 text-sm text-center">
                    <tr class="font-bold text-gray-500">
                      <th scope="col" class="px-3 py-3 font-medium text-gray-500 cursor-pointer">
                        <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#000000" viewBox="0 0 256 256"><path d="M220,48V208a12,12,0,0,1-12,12H136a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM117.17,157.17,64,210.34,42.83,189.17a4,4,0,0,0-5.66,5.66l24,24a4,4,0,0,0,5.66,0l56-56a4,4,0,0,0-5.66-5.66Z"></path></svg>
                      </th>
                      <th scope="col" class="px-6 py-3 ">
                        Tipo de diagnóstico
                      </th>
                      <th scope="col" class="px-6 py-3  ">
                        Tema
                      </th>
                      <th scope="col" class="px-6 py-3 ">
                        Vínculo ao PAI
                      </th>
                      <th scope="col" class="px-6 py-3 ">
                        Destinatário / Setor
                      </th>
                      <th scope="col" class="px-6 py-3 ">
                        Status
                      </th>
                      <th scope="col" class="px-6 py-3 ">
                        Nível conformidade
                      </th>
                      <th v-if="!$store.state.user.cliente" scope="col" class="px-6 py-3 ">
                        Avanço no PAI
                      </th>
                      <th scope="col" class="px-6 py-3 ">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200 text-center text-sm" >
                    <tr v-for="item in list" :key="item._id"  
                    :class="{
                      'bg-red-400 text-white': !item.ativo, 
                      'text-gray-500': item.ativo
                      }" 
                    >
                      <td class="px-3 py-4">
                        <div class="flex items-center">
                          <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <span class="items-center uppercase">
                            {{ item.tipoDiagnostico }}
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <span class="items-center">
                            {{ item.diagnosticoBase ? item.diagnosticoBase.tema : '' }}
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <span :class="' text-sm ' + (item.vinculado_pai ? ' text-blue-500':' text-red-400') ">
                            {{ item.vinculado_pai ? 'Sim' : 'Não' }}
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex flex-col items-center">
                            <span v-if="!item.quantidadeDestinatarios" >
                              Nenhum
                            </span>
                            <span v-if="item.quantidadeDestinatarios > 1" >
                              Vários ({{ item.quantidadeDestinatarios }})
                            </span>
                            
                            <span v-if="item.destinatariosPessoas.length === 1 && item.quantidadeDestinatarios === 1">
                              {{ `${item.destinatariosPessoas[0].destinatario.nome} ${item.destinatariosPessoas[0].destinatario.setor ? `- ${item.destinatariosPessoas[0].destinatario.setor.nome}` : ''}` }}
                            </span>

                            <span v-if="item.destinatariosAgente.length === 1 && item.quantidadeDestinatarios === 1">
                              {{ `${item.destinatariosAgente[0].destinatario.nome}` }}
                            </span>
                          
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex flex-col items-center">
                          <span v-if="item.destinatariosPessoas.length">
                            <span v-show="item.destinatariosPessoas.length > 1" >
                              Vários
                            </span>
                            <span v-show="item.destinatariosPessoas.length === 1">
                              {{ `${item.destinatariosPessoas.length ? item.destinatariosPessoas[0].status : ''}` }}
                              {{ 
                                 item.destinatariosPessoas.length && item.destinatariosPessoas[0].data_envio && item.destinatariosPessoas[0].status === "Enviado"
                                  ?`em ${formatDate(item.destinatariosPessoas[0].data_envio)}` 
                                  : ""
                              }}
                            </span>
                          </span>

                          <span v-else>
                            <span v-show="item.destinatariosAgente.length">
                              <span v-show="item.destinatariosAgente.length > 1">
                                Vários
                              </span>
                              <span v-show="item.destinatariosAgente.length === 1">
                                {{ `${item.destinatariosAgente.length ? item.destinatariosAgente[0].status : ''}` }}
                                {{ 
                                  item.destinatariosAgente.length && item.destinatariosAgente[0].data_envio && item.destinatariosAgente[0].status === "Enviado"
                                    ?`em ${formatDate(item.destinatariosAgente[0].data_envio)}` 
                                    : ""
                                }}
                              </span>
                            </span>
                          </span>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <span class="text-center">
                          {{ item.nivelConformidade ? `${item.nivelConformidade.toFixed(2)} %`: '-' }}
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex flex-col text-center">
                          <span class="font-bold text-black">
                            {{
                              item.avanco_pai ? `${item.avanco_pai.toFixed(2)} %`: '' 
                            }}
                          </span>
                          <span>
                            {{
                              item.pai_concluido_em ? `concluído em ${formatDate(item.pai_concluido_em)}`:'' 
                            }}
                            {{ !item.avanco_pai && !item.pai_concluido_em ? '-':'' }}
                          </span>
                        </div>
                      </td>
                      <td class="flex flex-wrap px-4 py-4 text-sm font-medium gap-1 items-center">
                        <router-link 
                          type="button" 
                          v-if="!$store.state.user.cliente && item.ativo" 
                          :to="`${route}/visualizar/${item._id}`" 
                          title="Visualizar"
                          class="text-white hover:bg-blue-800 bg-blue-700 rounded py-1 px-4 mx-1 ">
                            Visualizar
                        </router-link>
                        <button 
                          v-if="!item.enviada && item.ativo && !$store.state.user.cliente" 
                          @click="modalEnviar(item)" 
                          title="Enviar"
                          class="text-white hover:bg-purple-500 bg-purple-400 rounded py-1 px-4 mx-1 ">
                          Enviar
                        </button>
                        <router-link 
                          type="button" 
                          v-if="!$store.state.user.cliente && item.ativo" 
                          :to="`${route}/form/${item._id}`" 
                          title="Editar"
                          class="text-white hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-4 mx-1 ">
                          Editar
                        </router-link>
                          <button
                            v-if="!$store.state.user.cliente && item.ativo"
                            @click="duplicar(item._id)" 
                            title="Duplicar"
                            class="text-white hover:bg-slate-600 bg-slate-900 rounded py-1 px-4 mx-1 ">
                            Duplicar
                          </button>
                          <button
                            v-if="!$store.state.user.cliente && !arquivados"
                            @click="remove(item._id)" 
                            type="button" 
                            title="Arquivar"
                            class="text-white hover:bg-red-800 bg-red-600 rounded py-1 px-4 mx-1 ">
                            Arquivar
                          </button>
                          <button
                            v-else-if="!$store.state.user.cliente && arquivados"
                            @click="ativar(item._id)" 
                            type="button" 
                            title="Ativar"
                            class="text-black hover:bg-green-500 bg-green-400 rounded py-1 px-4 mx-1 ">
                            Ativar
                          </button>
                          <button
                            v-if="arquivados"
                            @click="removeArquivada(item._id)" 
                            type="button" 
                            title="Excluir"
                            class="text-white hover:bg-red-900 bg-red-800 rounded py-1 px-4 mx-1 ">
                            Excluir
                          </button>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');
export default {
  data() {
    return {
      route: '/diagnosticos',
      form: {
        responsavel: null,
        fornecedor: null,
        tratamento: '',
        tipo_responsavel:'',
      },
      resumo: null,
      optionsGeral: {
        chart: {
          id: 'chartGeral',
          type: 'donut',
        },
        labels: ["Adequado", "Não adequado", "Parcialmente adequado", "Não respondida"],
        colors: ['#00E396', '#FF4560', '#fcd34d', '#18181b']
      },
      seriesGeral: [0, 0, 0, 0],
      arquivados: false,
      list: [],
      selecionados: [],
      page: 1,
      perPage: 10,
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {
    formatDate(data) {
      if(!data) return '';
      return `${moment(data).format("DD/MM/YYYY")}`;
    },
    async start() {
      console.log('start')
      await this.$http.post(`/v1/diagnosticoControle/list`, { 
        arquivados: this.arquivados, 
        skip: this.query.skip, 
        limit: this.query.limit  
      })
      .then((resp)=>{
        this.list = resp.data.data;
        this.total = resp.data.total;
      })
      await this.grafico();
      
    },
    async grafico() {

      await this.$http.get(`/v1/diagnosticoControle/resumo/conformidade?empresa=${this.$store.state.empresa._id}`)
      .then((resp)=>{
        this.seriesGeral = [resp.data.adequadas, resp.data.naoAdequadas, resp.data.parciais, resp.data.naorespondido];
        this.resumo = resp.data;
      })
    },
    async modalEnviar(diagnostico){
      this.$confirm({
        title: 'Enviar diagnóstico',
        message: `Deseja enviar o diagnóstico para o(s) destinatários ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/diagnosticoAplicado/`, {_id: diagnostico._id})
          .then((resp)=>  this.$vToastify.success(resp.data.msg))
          this.start();
        }
      })
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/diagnosticoControle/selecionados/deletar`,{ selecionados: this.selecionados })
          .then((resp)=>{
            this.$vToastify.success(resp.data.msg);
            this.selecionados = [];
            this.start();
          })
          .catch(()=> this.$vToastify.error('Erro ao arquivar itens.'))
        }
      })
    },

    async removeArquivada (id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja excluir esse diagnóstico arquivado?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/diagnosticoControle/remover/${id}`);
              this.$vToastify.success("Excluído com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar esse diagnóstico?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/diagnosticoControle/${id}`)
              .then((resp)=>{
                this.$vToastify.success(resp.data.msg);
                this.page = 1;
                this.start();
              })
              .catch(()=> this.$vToastify.error('Erro ao arquivar item.'))
            }
          }
      });
    },

    async ativar(id) {
    this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja ativar esse diagnóstico?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.put(`/v1/diagnosticoControle/`, { _id: id, ativo: true })
              .then((resp)=>{
                this.$vToastify.success(resp.data.msg);
                this.page = 1;
                this.start();
              })
              .catch(()=> this.$vToastify.error('Erro ao ativar item.'))
            }
          }
      });
    },

    async duplicar(id) {
      await this.$http.post(`/v1/diagnosticoControle/duplicar/`,{ _id: id })
      .then(()=>{
        this.$vToastify.success("Duplicado com sucesso!");
        this.start();
      })
      .catch(()=>this.$vToastify.error('Erro ao duplicar item.'))
      
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { 
        page: this.query.page, 
        limit: this.query.limit, 
        skip: this.query.skip, 
        arquivados: this.arquivados 
      };
      this.$router.push({ path: `${this.route}`, query: this.query });
    },
    async selecionarTodos(){
      const listReq = await this.$http.post(`/v1/diagnosticoControle/list`, { all: true, arquivados: this.arquivados });
      this.selecionados = listReq.data.data;
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.arquivados = this.arquivados;

      this.$router.push({ path: `${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>